/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	/* --theme-background: linear-gradient(
		90deg,
		#1f1e2e 4%,
		#1f1e2e 39%,
		#1f1e2e 100%
	);
	--theme-color1: #3d62ed; */
  background-color: #fff !important;
}

body {
	background-color: #fff !important;
	height: 100vh !important;
}
/* 
@font-face {
  font-family: 'font light';
  src:url(./assets/fonts/MaisonNeue-Light.otf) format('truetype')
}

@font-face {
  font-family: 'font semibold';
  src:url(./assets/fonts/MaisonNeue-Demi.otf) format('truetype')
}

@font-face {
  font-family: 'font bold';
  src:url(./assets/fonts/MaisonNeue-Bold.otf) format('truetype')
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'font bold' , sans-serif !important;
}

a, p, div, span, li {
   font-family: 'font light' , sans-serif !important;
} */

:root {
  /* --primary-color: #00004E;
  --secondary-color: #FFA500;
  --warn-color: #E74458; */
}

button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

@keyframes s2 {
  100% {
    transform: rotate(.5turn)
  }
}
